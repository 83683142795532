import React from 'react'
import { useHeroStyles } from './hero.styles'
import { CenterContent } from '../../center-content/center-content.component'
import { Subtitle1, Title1, Image } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import decoration from './svg/decoration.svg'
import profile from '../../../images/timor.jpg'

type THero = {
    className?: string
}

export function Hero({ className, ...rest }: THero) {
    const { rootStyle, styles } = useHeroStyles({ className })
    const { t } = useTranslation()

    return (
        <CenterContent className={rootStyle} {...rest}>
            <CenterContent className={styles.textContainer}>
                <Title1 className={styles.title}>{t('hero.title')}</Title1>
                <Subtitle1 className={styles.subtitle}>
                    {t('hero.subtitle')}
                </Subtitle1>
            </CenterContent>
            <CenterContent className={styles.imageContainer}>
                <Image shape="circular" fit="cover" src={profile} />
            </CenterContent>
            <Image className={styles.decoration} src={decoration} />
        </CenterContent>
    )
}
