import { makeStyles, mergeClasses } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    container: {
        display: 'flex',
        gap: '30px',
    },
    button: {
        cursor: 'pointer',
    },
})

type TUseTopBarStyles = {
    className?: string
}

export function useTopBarStyles({ className }: TUseTopBarStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
