import { makeStyles, mergeClasses } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: { cursor: 'pointer' },
})

type TUseCenterContentStyles = {
    className?: string
}

export function useCenterContentStyles({ className }: TUseCenterContentStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
