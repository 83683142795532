import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { COLOR_GREEN } from '../../../constants'

const useStyles = makeStyles({
    root: {
        flexDirection: 'column',
        paddingTop: '110px',
        gap: '71px',
    },
    textContainer: {
        flexDirection: 'column',
        gap: '18px',
    },
    title: {
        fontWeight: '800',
        fontSize: '50px',
        textAlign: 'center',
        lineHeight: '1.1',
    },
    subtitle: {
        textAlign: 'center',
    },
    imageContainer: {
        position: 'relative',
        width: '275px',
        height: '275px',
        borderRadius: '50%',
        border: `10px solid ${COLOR_GREEN}`,
    },
    decoration: {
        paddingTop: '60px',
        width: '100%',
    },
})

type TUseHeroStyles = {
    className?: string
}

export function useHeroStyles({ className }: TUseHeroStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
