import React from 'react'
import { useCenterContentStyles as useToggleLanguageStyles } from './toggle-language.styles'
import { Avatar } from '@fluentui/react-components'
import flagOfSweden from './svg/sweden.svg'
import flagOfUnitedKingdoms from './svg/united-kingdom.svg'
import { useTranslation } from 'react-i18next'

type TToggleLanguage = {
    className?: string
}

export function ToggleLanguage({ className, ...rest }: TToggleLanguage) {
    const { rootStyle } = useToggleLanguageStyles({ className })
    const { i18n } = useTranslation()
    const selectedLanguage = i18n.resolvedLanguage

    const flag = selectedLanguage === 'sv' ? flagOfSweden : flagOfUnitedKingdoms
    return (
        <Avatar
            image={{ src: flag }}
            size={24}
            className={rootStyle}
            onClick={() =>
                i18n.changeLanguage(selectedLanguage === 'sv' ? 'en' : 'sv')
            }
            {...rest}
        />
    )
}
