import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
    title: {
        color: 'white',
        fontWeight: '800',
        fontSize: '28px',
        textAlign: 'center',
    },
    body: {
        color: 'white',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: '1.5',
        fontStyle: 'semi-bold',
    },
})

export function useTextStyles() {
    const styles = useStyles()
    const titleStyle = styles.title
    const bodyStyle = styles.body
    return { titleStyle, bodyStyle }
}
