import React from 'react'
import { useSocialMediaStyles } from './social-media.styles'
import { CenterContent } from '../center-content/center-content.component'
import { Image } from '@fluentui/react-components'
import githubLogo from './svg/github.svg'
import linkedInLogo from './svg/linked-in.svg'

type TSocialMedia = {
    className?: string
}

export function SocialMedia({ className, ...rest }: TSocialMedia) {
    const { rootStyle, styles } = useSocialMediaStyles({ className })
    return (
        <div className={rootStyle} {...rest}>
            <CenterContent>
                <Image
                    className={styles.logo}
                    onClick={() => redirectTo('https://github.com/TimorDadgar')}
                    src={githubLogo}
                />
            </CenterContent>
            <CenterContent>
                <Image
                    className={styles.logo}
                    onClick={() =>
                        redirectTo('https://www.linkedin.com/in/timor-dadgar/')
                    }
                    src={linkedInLogo}
                />
            </CenterContent>
        </div>
    )
}

function redirectTo(url: string) {
    window.location.href = url
}
