import { makeStyles, mergeClasses } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: {},
    logo: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
    },
})

type TUseSocialMediaStyles = {
    className?: string
}

export function useSocialMediaStyles({ className }: TUseSocialMediaStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
