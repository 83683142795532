import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          topbar: {
            portfolio: 'Portfolio',
            contact: 'Contact',
          },
          hero: {
            title: 'Timor Dadgar, at your service',
            subtitle: 'I create and maintain the backend, and I have a fiery love for C++.',
          },
          introduction: {
            title: 'Nice to meet you, I\'m Timor',
            body: "I love playing games and making games, especially in Unreal Engine 5. I have experience in Unity, but I prefer Unreal Engine. I also have experience with large-scale C++/C# systems from my time at Volvo, as well as my own projects. I am constantly working on new projects in C# and .NET. When you do not find me at my computer, you will probably still find me at my computer. One thing I can mention is that I am a big foodie. During my five years at Örebro Universitet I am pretty sure I tried all of the restaurants in Örebro. Besides food I also enjoy playing chess and watching anime."
        },
        contact: {
          title: "Get to know me better",
          subtitle: "Don’t worry, I won’t bite",
          mail: "timor.dadgar@gmail.com",
          phone: "+46 72-326 94 50",
        },
        portfolio: {
          pirateGame:{title: "Pirate Loot Box Game", linkText: "Available at itch.io.", description: "This game is a pirate loot box simulator where you open lootboxes with a chance to win a jewel, when you're out of coins you have to watch a commercial that we made. The game was our entry for a two day game jam, it was made in Unreal Engine 5 written in both C++ & blueprints. There were a lot of challenges, from designing the different aspects of the game to be as scalable as possible using UML diagrams and use cases, event-driven architecture and decoupling services where it could help. Showcased in the handling of a lot of assets (especially sound assets), making sure that they trigger at the right times at the right events etc. Working as a team with an animator, 3d artist and also two developers is a challenge and being able to work fluidly in Git together really made it all come together. For being the first time attending a game jam, it was super fun."},
          openGl:{title: "OpenGL Raytracing C++", description: "A project in C++ where I implemented ray tracing from scratch using linked lists and shader programming. I first started with the standard OpenGL bunny and then moved on to implementing Fresnel equations/BRDF and then raycasting. It has been very interesting to explore the rendering equation and see the effects different parameters can have on a rendered scene. It has been a great way to learn about lighting. I learned how to create a 3D scene, program different kinds of lighting, transform 3D models, and parameterize materials, all of which has practical applications in realtime game engines as well."},
          mouseEat:{title: "Multiplayer Slajm Game", description: "Slajm Game is my baby that I have been working with for quite some time, it's a multiplayer game where I learnt all the principles of building scalable online games. I have an updated private git repo where I actively post updates to the game, constantly adding new features. The game has steam integration with possibility to invite your friends. It has a round system that syncronizes the clock among clients. Abilities also, since it is a multiplayer fighting game after all. And also custom made assets that I've created in Blender."},
          picOff:{title: "PicOff, Android App", description: "PicOff is an Android app where you can challenge your friends, imagine Snapchat but with challenges and a points system. The app is made in Kotlin and its backend uses Firebase Authentication with Google Account for identification and Firebase Realtime Database for data storage. It also uses concepts like Firebase RTDB Objects and View Models. A fun project at university that really gave a good understanding of backend."},
          randomGames:{title: "Honorable mentions", description: "Many of the projects here have been super fun to explore. In Python using Pygame, I created a 2D platformer game with a tile array for level design, custom collision boxes, and a particle system for various effects in the game. Additionally, there's the rock-paper-scissors game which is online, where the backend is entirely made with socket communication."}
        },
        copyright: "© Timor Dadgar",
      },
    },
    sv: {
      translation: {
        topbar: {
          portfolio: 'Portfolio',
          contact: 'Kontakt',
        },
        hero: {
          title: 'Timor Dadgar, at your service',
          subtitle: 'Jag utvecklar och underhåller backend och jag har en brinnande kärlek till C++.',
        },
        introduction: {
          title: 'Trevligt att träffas, mitt namn är Timor',
          body: 'Jag älskar att spela spel och att utveckla spel, speciellt i Unreal Engine 5. Jag har erfarenhet av Unity men jag föredrar Unreal Engine. Jag har även erfarenhet av storskaliga C++/C# system från min tid hos Volvo men även egna byggen. Arbetar ständigt med nya projekt i C# och .NET. När du inte hittar mig vid min dator hittar du förmodligen mig vid min dator. En sak jag kan nämna är att jag är en stor foodie. Under mina fem på Örebro Universitet är jag säker på att jag testade alla restauranger i Örebro. Utöver mat tycker jag om schack och att titta på anime.'
        },
        contact: {
          title: "Lär känna mig mer",
          subtitle: "Här är mina kontaktuppgifter",
          mail: "timor.dadgar@gmail.com",
          phone: "+46 72-326 94 50",
        },
        portfolio: {
          pirateGame:{title: "Pirate Loot Box Game", linkText: "Tillgängligt på itch.io.", description: "Detta spelet är en pirat loot box simulator där man öppnar en lootbox i chans om att vinna en juvel och när man får slut på pengar behöver man kolla på en reklam som vi har skapat. Spelet var vårt bidrag till ett tvådagars game jam och gjort i Unreal Engine 5 skrivet i C++ och blueprints. Det var många utmaningar, från att designa de olika funktionerna till att göra det så skalbart som möjligt, hantera många tillgångar (särskilt ljudtillgångar) och att se till att de triggar vid rätt tidpunkter vid rätt händelser etc. Att arbeta som ett team med en animatör, en 3D-artist och även två utvecklare är en utmaning och att kunna arbeta smidigt i Git tillsammans gjorde verkligen att allt föll på plats. För att vara vår första gång i ett game jam tycker jag det gick väldigt bra och det var en väldigt kul upplevelse."},
          openGl:{title: "OpenGL Raytracing C++", description: "Ett projekt i C++ där jag implementerade raytracing från grunden med länkade listor och shader programmering. Började först med standard OpenGL kaninen och sedan med implementering av fresnel ekvationer/brdf och sedan raycasting. Det har varit intressant att utforska renderingsekvationer och att se hur olika parametrar ger olike effekter på den renderade scenen. Det har varit ett väldigt bra sätt att lära sig om ljussättning. Jag lärde mig hur man skapar en 3D scen, programmera varierande ljus, transformera 3D modeller och parametrisera material, allt detta har även praktisk användning inom realtids spelmotorer."},
          mouseEat:{title: "Multiplayer Slajm Game", description: "Slajm Game är mitt hjärteprojekt som jag har arbetat med under en lång tid. Det är ett multiplayer-spel där jag har lärt mig alla principer för att bygga skalbara onlinespel. Jag har ett ständigt uppdaterad privat Git-repo där jag aktivt lägger upp uppdateringar till spelet och nya funktioner. Spelet har Steam-integration med möjlighet att bjuda in dina vänner. Det har ett rundsystem som synkroniserar klockan mellan klienter. Det finns också olika abilities, eftersom det trots allt är ett multiplayer-fightingspel. Dessutom har jag skapat custom-made assets i Blender. "},
          picOff:{title: "PicOff, Android App", description: "PicOff är en android app där man kan utmana sina vänner, tänk dig Snapchat fast med utmaningar och poängsystem. Appen är gjord i Kotlin och dess backend använder Firebase Authentification med Google Account för identifikation och Firebase Realtime Database för databasutrymme. Även användning av koncept som Firebase RTDB Objekt och View Models. Ett roligt universitetsprojekt som verkligen gav bra förståelse för backend."},
          randomGames:{title: "Honorable mentions", description: "Mycket av projekten här är projekt som har varit superroliga att utforska. I Python med hjälp av Pygame, skapade jag en 2D plattformerspel med en tile array för level design, custom collision boxes och partikelsystem för olika effekter i spelet. Även här finns sten-sax-påse spelet som är ett onlinespel och där backenden är helt och hållet gjort med socket kommunikation"}
        },
        copyright: "© Timor Dadgar",
      }
    }
  }});

export default i18n;