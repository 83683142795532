import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { useTextStyles } from '../../common-styling/text.styles'

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        gap: '34px',
        flexDirection: 'column',
    },
    text: { color: 'black' },
})

type TUseProjectStyles = {
    className?: string
}

export function useProjectStyles({ className }: TUseProjectStyles) {
    const styles = useStyles()
    const { titleStyle, bodyStyle } = useTextStyles()

    const rootStyle = mergeClasses(styles.root, className)
    const subtitleStyle = mergeClasses(titleStyle, styles.text)
    const descriptionStyle = mergeClasses(bodyStyle, styles.text)
    return { rootStyle, styles, subtitleStyle, descriptionStyle }
}
