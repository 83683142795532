import React from 'react'
import { useTopBarStyles } from './top-bar.styles'
import { Subtitle2 } from '@fluentui/react-components'
import { CenterContent } from '../center-content/center-content.component'
import { SocialMedia } from '../social-media/social-media.component'
import { useTranslation } from 'react-i18next'
import { ToggleLanguage } from '../change-language/toggle-language.component'

type TTopBar = {
    className?: string
    onPortfolioClick: () => void
    onContactClick: () => void
}

export function TopBar({
    className,
    onPortfolioClick,
    onContactClick,
    ...rest
}: TTopBar) {
    const { rootStyle, styles } = useTopBarStyles({ className })
    const { t } = useTranslation()
    return (
        <div id="top-bar" className={rootStyle} {...rest}>
            <SocialMedia className={styles.container} />
            <div className={styles.container}>
                <CenterContent>
                    <Subtitle2
                        onClick={onPortfolioClick}
                        className={styles.button}
                    >
                        {t('topbar.portfolio')}
                    </Subtitle2>
                </CenterContent>
                <CenterContent>
                    <Subtitle2
                        onClick={onContactClick}
                        className={styles.button}
                    >
                        {t('topbar.contact')}
                    </Subtitle2>
                </CenterContent>
                <CenterContent>
                    <ToggleLanguage />
                </CenterContent>
            </div>
        </div>
    )
}
