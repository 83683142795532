import React, { useRef } from 'react'
import { useAppStyles } from './App.styles'
import { TopBar } from './components/top-bar/top-bar.component'
import { Hero } from './components/sections/hero/hero.component'
import { Introduction } from './components/sections/introduction/introduction.component'
import { Portfolio } from './components/sections/portfolio/portfolio.component'
import { Contact } from './components/sections/contact/contact.component'
import { CenterContent } from './components/center-content/center-content.component'
import { Body1 } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

function App() {
    const { rootStyle, styles, copyrightStyle } = useAppStyles({})
    const { t } = useTranslation()
    const portfolioRef = useRef<HTMLDivElement | null>(null)
    const contactRef = useRef<HTMLDivElement | null>(null)

    const scrollToPortfolio = () => {
        if (portfolioRef.current) {
            portfolioRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }
    const scrollToContact = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div className={rootStyle}>
            <div className={styles.pageContent}>
                <TopBar
                    onPortfolioClick={scrollToPortfolio}
                    onContactClick={scrollToContact}
                />
                <Hero />
            </div>
            <Introduction />
            <div className={styles.pageContent} ref={portfolioRef}>
                <Portfolio />
            </div>
            <Contact />
            <div ref={contactRef} />
            <CenterContent>
                <Body1 className={copyrightStyle}> {t('copyright')} </Body1>
            </CenterContent>
        </div>
    )
}

export default App
