import React from 'react'
import { useIntroductionStyles } from './introduction.styles'
import { CenterContent } from '../../center-content/center-content.component'
import { Body1, Subtitle1 } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useTextStyles } from '../../../common-styling/text.styles'

type TIntroduction = {
    className?: string
}

export function Introduction({ className, ...rest }: TIntroduction) {
    const { rootStyle, styles } = useIntroductionStyles({ className })
    const { titleStyle, bodyStyle } = useTextStyles()
    const { t } = useTranslation()
    return (
        <CenterContent className={rootStyle} {...rest}>
            <CenterContent className={styles.textContainer} {...rest}>
                <Subtitle1 className={titleStyle}>
                    {t('introduction.title')}
                </Subtitle1>
                <Body1 className={bodyStyle}>{t('introduction.body')}</Body1>
            </CenterContent>
        </CenterContent>
    )
}
