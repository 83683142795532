import { makeStyles, mergeClasses } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        paddingTop: '132px',
        paddingBottom: '132px',
        gap: '132px',
    },
})

type TUsePortfolioStyles = {
    className?: string
}

export function usePortfolioStyles({ className }: TUsePortfolioStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
