import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { COLOR_GREEN, PAGE_PADDING, PAGE_WIDTH } from '../../../constants'

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: COLOR_GREEN,
        minHeight: '456px',
    },
    textContainer: {
        paddingTop: '132px',
        paddingBottom: '132px',
        paddingLeft: PAGE_PADDING,
        paddingRight: PAGE_PADDING,
        flexDirection: 'column',
        gap: '34px',
        maxWidth: PAGE_WIDTH,
        boxSizing: 'border-box',
    },
})

type TUseIntroductionStyles = {
    className?: string
}

export function useIntroductionStyles({ className }: TUseIntroductionStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
