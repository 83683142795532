import { PropsWithChildren } from "react";
import React from 'react';
import { useCenterContentStyles } from "./center-content.styles";

type TCenterContent = {
    className?: string;
} & PropsWithChildren;

export function CenterContent({ className, children, ...rest }: TCenterContent) {
    const { rootStyle } = useCenterContentStyles({ className });
    return (
        <div className={rootStyle} {...rest}>
            {children}
        </div>
    );
}