import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { COLOR_GREEN, PAGE_PADDING, PAGE_WIDTH } from '../../../constants'
import { useTextStyles } from '../../../common-styling/text.styles'

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        gap: '34px',
        backgroundColor: COLOR_GREEN,
        paddingTop: '61px',
        paddingBottom: '61px',
        minHeight: '456px',
    },
    textContainer: {
        paddingLeft: PAGE_PADDING,
        paddingRight: PAGE_PADDING,
        flexDirection: 'column',
        gap: '8px',
        maxWidth: PAGE_WIDTH,
        boxSizing: 'border-box',
    },
    imageContainer: {
        position: 'relative',
        width: '144px',
        height: '144px',
        borderRadius: '50%',
        border: `4px solid white`,
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '2px',
    },
    linkContainer: {
        gap: '8px',
        paddingLeft: PAGE_PADDING,
        paddingRight: PAGE_PADDING,
    },
    contactInfoContainer: { gap: '8px', flexDirection: 'column' },
    icon: { color: 'white' },
})

type TUseContactStyles = {
    className?: string
}

export function useContactStyles({ className }: TUseContactStyles) {
    const styles = useStyles()
    const { bodyStyle } = useTextStyles()

    const rootStyle = mergeClasses(styles.root, className)
    const linkStyle = mergeClasses(bodyStyle, styles.link)
    return { rootStyle, styles, linkStyle }
}
