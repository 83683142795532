import React from 'react'
import { useContactStyles } from './contact.styles'
import { useTextStyles } from '../../../common-styling/text.styles'
import { CenterContent } from '../../center-content/center-content.component'
import { Body1, Subtitle1, Image } from '@fluentui/react-components'
import { Mail24Regular, Phone24Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'
import profile from '../../../images/timor.jpg'

type TContact = {
    className?: string
}

export function Contact({ className, ...rest }: TContact) {
    const { rootStyle, styles, linkStyle } = useContactStyles({ className })
    const { titleStyle, bodyStyle } = useTextStyles()
    const { t } = useTranslation()

    return (
        <CenterContent className={rootStyle} {...rest}>
            <CenterContent className={styles.textContainer} {...rest}>
                <Subtitle1 className={titleStyle}>
                    {t('contact.title')}
                </Subtitle1>
                <Body1 className={bodyStyle}>{t('contact.subtitle')}</Body1>
            </CenterContent>
            <CenterContent className={styles.imageContainer}>
                <Image shape="circular" fit="cover" src={profile} />
            </CenterContent>
            <CenterContent className={styles.contactInfoContainer}>
                <CenterContent className={styles.linkContainer}>
                    <Mail24Regular className={styles.icon} />
                    <a
                        className={linkStyle}
                        href="mailto:timor.dadgar@gmail.com"
                    >
                        {t('contact.mail')}
                    </a>
                </CenterContent>
                <CenterContent className={styles.linkContainer}>
                    <Phone24Regular className={styles.icon} />
                    <a className={linkStyle} href="tel:+46723269450">
                        {t('contact.phone')}
                    </a>
                </CenterContent>
            </CenterContent>
        </CenterContent>
    )
}
