import React from 'react'
import { useImageCollectionStyles } from './image-collection.styles'
import { Image } from '@fluentui/react-components'
import { CenterContent } from '../center-content/center-content.component'

type TDoubleWideImageCollection = {
    image1: string
    image2: string
    className?: string
    fit?: 'contain' | 'cover' | 'none' | 'center' | 'default'
}

export function DoubleWideImageCollection({
    className,
    fit = 'cover',
    image1,
    image2,
    ...rest
}: TDoubleWideImageCollection) {
    const { rootStyle, wideImageContainer } = useImageCollectionStyles({
        className,
    })
    return (
        <CenterContent className={rootStyle} {...rest}>
            <CenterContent className={rootStyle} {...rest}>
                <CenterContent className={wideImageContainer}>
                    <Image fit={fit} src={image1} />
                </CenterContent>
                <CenterContent className={wideImageContainer}>
                    <Image fit={fit} src={image2} />
                </CenterContent>
            </CenterContent>
        </CenterContent>
    )
}
