import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { PAGE_PADDING, PAGE_WIDTH } from './constants'
import { useTextStyles } from './common-styling/text.styles'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: PAGE_WIDTH,
        paddingLeft: PAGE_PADDING,
        paddingRight: PAGE_PADDING,
        boxSizing: 'border-box',
    },
    copyright: {
        fontSize: '14px',
        color: 'black',
        padding: '20px',
    },
})

type TAppStyles = {
    className?: string
}

export function useAppStyles({ className }: TAppStyles) {
    const styles = useStyles()
    const { bodyStyle } = useTextStyles()
    const rootStyle = mergeClasses(styles.root, className)
    const copyrightStyle = mergeClasses(bodyStyle, styles.copyright)
    return { rootStyle, styles, copyrightStyle }
}
