import React from 'react'
import { useImageCollectionStyles } from './image-collection.styles'
import { Image } from '@fluentui/react-components'
import { CenterContent } from '../center-content/center-content.component'

type TTripleImageCollection = {
    image1: string
    image2: string
    image3: string
    className?: string
    fit?: 'contain' | 'cover' | 'none' | 'center' | 'default'
}

export function TripleImageCollection({
    className,
    fit = 'cover',
    image1,
    image2,
    image3,
    ...rest
}: TTripleImageCollection) {
    const { rootStyle, styles } = useImageCollectionStyles({ className })
    return (
        <CenterContent className={rootStyle} {...rest}>
            <CenterContent className={styles.imageContainer}>
                <Image fit={fit} src={image1} />
            </CenterContent>
            <CenterContent className={styles.imageContainer}>
                <Image fit={fit} src={image2} />
            </CenterContent>
            <CenterContent className={styles.imageContainer}>
                <Image fit={fit} src={image3} />
            </CenterContent>
        </CenterContent>
    )
}
