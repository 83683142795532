import React from 'react'
import { useProjectStyles } from './project.styles'
import { CenterContent } from '../center-content/center-content.component'
import { Body1, Link, Subtitle1 } from '@fluentui/react-components'

type TProject = {
    title: string
    description: string
    content: React.ReactNode
    className?: string
    projectLink?: string
    projectLinkText?: string
}

export function Project({
    className,
    title,
    description,
    content,
    projectLink,
    projectLinkText: linkText = 'Press here to learn more.',
    ...rest
}: TProject) {
    const { rootStyle, subtitleStyle, descriptionStyle } = useProjectStyles({
        className,
    })

    return (
        <CenterContent className={rootStyle} {...rest}>
            <Subtitle1 className={subtitleStyle}>{title}</Subtitle1>
            {content}
            <Body1 className={descriptionStyle}>
                {description}{' '}
                {projectLink && (
                    <Link href={projectLink} inline>
                        {linkText}
                    </Link>
                )}
            </Body1>
        </CenterContent>
    )
}
