import React from 'react'
import { usePortfolioStyles } from './portfolio.styles'
import { CenterContent } from '../../center-content/center-content.component'
import { Project } from '../../project/project.component'
import { useTranslation } from 'react-i18next'
import { TripleImageCollection } from '../../image-collection/image-collection.component'

import picoff1 from '../../../images/picoff/android_app.png'
import picoff2 from '../../../images/picoff/android_app2.png'
import picoff3 from '../../../images/picoff/android_app3.png'
import pirateGame1 from '../../../images/pirate-game/pirate_gold.png'
import pirateGame2 from '../../../images/pirate-game/pirate_menu.png'
import pirateGame3 from '../../../images/pirate-game/pirate_ad.png'
import openGl2 from '../../../images/open-gl/graphics_2.png'
import openGl3 from '../../../images/open-gl/graphics_3.jpg'
import mouseEat1 from '../../../images/mouse-eat/mouse-eat1.png'
import mouseEat2 from '../../../images/mouse-eat/mouse-eat2.png'
import platformGame from '../../../images/random-games/platform-game1.png'
import onlineGame2 from '../../../images/random-games/online-game2.png'
import { DoubleWideImageCollection } from '../../image-collection/wide-image-collection.component'

type TPortfolio = {
    className?: string
}

export function Portfolio({ className, ...rest }: TPortfolio) {
    const { rootStyle } = usePortfolioStyles({ className })
    const { t } = useTranslation()

    return (
        <CenterContent className={rootStyle} {...rest}>
            <Project
                title={t('portfolio.mouseEat.title')}
                description={t('portfolio.mouseEat.description')}
                content={
                    <DoubleWideImageCollection
                        image1={mouseEat1}
                        image2={mouseEat2}
                    />
                }
            />
            <Project
                title={t('portfolio.openGl.title')}
                description={t('portfolio.openGl.description')}
                content={
                    <DoubleWideImageCollection
                        image1={openGl2}
                        image2={openGl3}
                    />
                }
            />
            <Project
                title={t('portfolio.pirateGame.title')}
                description={t('portfolio.pirateGame.description')}
                projectLink="https://peaonmyear.itch.io/pirate-loot-chest"
                projectLinkText={t('portfolio.pirateGame.linkText')}
                content={
                    <TripleImageCollection
                        image1={pirateGame1}
                        image2={pirateGame2}
                        image3={pirateGame3}
                    />
                }
            />
            <Project
                title={t('portfolio.randomGames.title')}
                description={t('portfolio.randomGames.description')}
                content={
                    <DoubleWideImageCollection
                        image1={platformGame}
                        image2={onlineGame2}
                    />
                }
            />
            <Project
                title={t('portfolio.picOff.title')}
                description={t('portfolio.picOff.description')}
                content={
                    <TripleImageCollection
                        image1={picoff1}
                        image2={picoff2}
                        image3={picoff3}
                        fit="contain"
                    />
                }
            />
        </CenterContent>
    )
}
