import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { COLOR_GREEN } from '../../constants'

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
        gap: '45px',
        flexWrap: 'wrap',
    },
    imageContainer: {
        maxHeight: '360px',
        aspectRatio: '1 / 1',
        borderRadius: '50px',
        border: `5px solid ${COLOR_GREEN}`,
        overflow: 'hidden',
    },
    wide: {
        aspectRatio: '158/100',
    },
    megaWide: {
        width: '1166px',
    },
})

type TUseImageCollectionStyles = {
    className?: string
}

export function useImageCollectionStyles({
    className,
}: TUseImageCollectionStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    const wideImageContainer = mergeClasses(styles.imageContainer, styles.wide)
    const megaWideImageContainer = mergeClasses(
        styles.imageContainer,
        styles.megaWide
    )
    return { rootStyle, styles, wideImageContainer, megaWideImageContainer }
}
