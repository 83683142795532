import { makeStyles, mergeClasses } from '@fluentui/react-components'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

type TUseCenterContentStyles = {
    className?: string
}

export function useCenterContentStyles({ className }: TUseCenterContentStyles) {
    const styles = useStyles()
    const rootStyle = mergeClasses(styles.root, className)
    return { rootStyle, styles }
}
